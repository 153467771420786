
import './styles/App.css';
import React, {useState, useEffect} from 'react';
import CompTable from './compTable';
import Header from './home/header';
import TodayMatch from './home/todayMatch';
import weekly from './data/today-matches.json'
import Footer from './home/footer';

function App() {
  
  const [todayGameData, setTodayGameData] = useState()
  const [savedTodayGameData, setSavedTodayGameData] = useState()
useEffect(()=> {
  setTodayGameData(weekly)
  setSavedTodayGameData(weekly)
    }, []);
    
    function leagueIndexOutput(competitionId) {
      setTodayGameData(savedTodayGameData)
      let selectedCompetition = []
      for (let i = 0; i < savedTodayGameData.length; i++) {
        const element = savedTodayGameData[i];
        if (element.competition.id === competitionId){
          selectedCompetition.push(element)
        }
      }
      setTodayGameData(selectedCompetition)
    };

  return (
    <div className="App">
      <Header savedTodayGameData={savedTodayGameData} leagueIndexOutput={leagueIndexOutput} />
        <TodayMatch todayGameData={todayGameData} />
      <CompTable/>
      <Footer/>
    </div>
  );
}

export default App;
