import '../styles/graphs.css'
import  React,{ useState, useEffect } from 'react';
import { PieChart, Pie, Cell } from 'recharts';


  
  const COLORS = ['#2222a6', '#a52a2a'];

function Graphs(props) {
const matchSVG = props.matchSVG
const timeDeFora = props.timeDeFora
const [graphData, setGraphData] = useState([{ id: 'team 1', value: 0 }, { id: 'team 2', value: 0}])
useEffect(()=> {
    let oldArr = [...graphData]
    const leftTeamPoints = Array.from(document.getElementsByClassName("left backgroundGreen"));
    const rightTeamPoints = Array.from(document.getElementsByClassName("right backgroundGreen"));
    oldArr[0].value = leftTeamPoints.length
    oldArr[1].value = rightTeamPoints.length
    setGraphData(oldArr)
},[timeDeFora])

return(
    <>
    <div className='image-row'>
    <img className='team1' src={matchSVG[0]} alt='Imagem do respectivo time' />

    <PieChart width={160} height={160} >
    <Pie
        data={graphData}
        cx={75}
        cy={75}
        innerRadius={60}
        outerRadius={79}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
        startAngle={-267}
    >
        {graphData.map((entry, index) => (
        <Cell style={{
            filter: `drop-shadow(0px 0px 7px ${COLORS[index % COLORS.length]}`,
            outline: "none",
            }} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
    </Pie>
    </PieChart>
      
    <img className='team2' src={matchSVG[1]} alt='Imagem do respectivo time' />
    </div>
    {/* <div className='quantidadeDePartidas_info'>
        
        <span>Dados de {timeDaCasa} partidas</span>
        <span>Dados de {timeDeFora} partidas</span>
    </div> */}
    </>
)}

    export default Graphs