import React from "react";
import '../styles/gptArea.css'

function GptArea(props) {
    const gpt_text = props.gpt_text
    const teamNames = props.teamNames

    return  (
        <>
    {/* {gpt_text ? 
    <div className="gptAreaParent">
        <h2><span className="h2prognostico">Prognóstico da partida  </span> <br/><br/>
        <span className="team1name">{teamNames[0]}</span>  x  <span className="team2name">{teamNames[1]}</span></h2>

        <span>
            {gpt_text}
        </span>
        </div>
        :''} */}
    </>
    )}

export default GptArea