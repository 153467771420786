import React from "react";
import '../styles/rowOfCard.css'
import Head2head from "./head2head";
import { useInView } from "react-intersection-observer";
import SideAd from "./sideAd";
function RowOfCard(props) {
  const i = props.i;
const match = props.match;
const estatisticaButton = props.estatisticaButton


const [ref, inView] = useInView({
  /* Optional options */
  triggerOnce: true,
  rootMargin: '0px 0px',
})

// TRECHO QUE DEFINE O DIA DA PARTIDA
const utcDate = new Date(match.utcDate)
const currentDate = new Date()
let monthName = new Intl.DateTimeFormat('pt-BR', {timeZone: 'Brazil/East',weekday:'long',day:'numeric', month:'long'}).format
let longDate = monthName(utcDate)
match.date = longDate
// FIM
// 'Adiados' está verificando se ja tem nova data agendada, se nao tiver ele printa 'Adiado'
if (match.status === "POSTPONED") {
  if (utcDate - currentDate>0) {
    let utcDateMinutes = utcDate.getMinutes()
    if (utcDateMinutes === 0) {
      utcDateMinutes = '00'
    }
    match.status = utcDate.getHours()+':'+utcDateMinutes
  }else{
  match.status = "Adiado"
}
}if (match.status === "FINISHED") {
  match.status = "Encerrado"
}if (match.status === "TIMED" || match.status === 'SCHEDULED'|| match.status === 'PAUSED'||match.status ==="IN_PLAY") {
  // se o time estiver jogando no momento (conta feita em milisegundos)
  if ((utcDate-currentDate)>=(-5700000) && utcDate - currentDate<0) {
    match.status = "Em Jogo"
  }
  else{
      if (utcDate - currentDate>0) {
        let utcDateMinutes = utcDate.getMinutes()
      if (utcDateMinutes === 0) {utcDateMinutes = '00'}
      match.status = utcDate.getHours()+':'+utcDateMinutes
      }else{
      match.status = "Encerrado"
  }}
}



    return (
      <div ref={ref}>
      { inView ?(
      <div className="card"  >
<div className="flex-row">
  <img loading="lazy" src={match.homeTeam.crest} alt="Escudo do respectivo time" title={match.homeTeam.name} className="img" />
  <div className="divLeague">
  <img loading="lazy" className="imgLeague" alt="Escudo da liga" title={match.competition.name} src={match.competition.emblem} />
  </div>
  <img loading="lazy" src={match.awayTeam.crest} alt="Escudo do respectivo time" title={match.awayTeam.name} className="img" />
</div>
<div className="flex-row">
  <h4 className="teamName">{match.homeTeam.name}</h4>
  <h4 className="teamName">{match.awayTeam.name}</h4>
</div>
<div className="statisticArea">
  <div className="time-div">
  <h5>{match.date}</h5>
<span 
style={{color: match.status === 'Encerrado'|| match.status === 'Em Jogo' ? '#32d300' : '#ffce07'}} className="timeToGame">{match.status}</span>
</div>

  <Head2head i={i}
    matchId={match.id}
    team1Crest={match.homeTeam.crest}
    team2Crest={match.awayTeam.crest}
    team1name={match.homeTeam.name}
    team2name={match.awayTeam.name}
      />

<a className="a-tag-estatistica" href={match.id} onClick={(e) => {e.preventDefault();window.history.replaceState("", "",match.id)}} >
  <button onClick={() => {estatisticaButton(match.id, match.homeTeam.name, match.awayTeam.name,match.homeTeam.id, match.awayTeam.id, match.homeTeam.crest, match.awayTeam.crest)}} >
  
  Estatísticas
  
    </button></a>
    <a href={match.id} >competiçao</a>
  </div>

  </div>
  ):''}
</div>
)}

export default RowOfCard
