import '../styles/todayMatch.css'
import '../styles/rowOfCard.css'
import  React,{ useState, useEffect } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react'
import RowOfCard from "./rowOfCard";
import Graphs from './graphs';
import GptArea from './gptArea';
// import GPT_response from '../data/GPT_response.json'
import Poisson from './poisson';
import fbrefData from '../data/fbrefData.json'
import GraphChild from './graphChild';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
function TodayMatch(props) {
const todayGameData = props.todayGameData
const { matchId } = useParams()

const [slidesPerViewNum, setSlidePerViewNum] = useState(4)
useEffect(()=>{ 
    function handleResize() {
            let window_width = Math.round((window.innerWidth/4)/100)
            setSlidePerViewNum(window_width)
    }
handleResize();
window.addEventListener("resize", handleResize)
return () => {
    window.removeEventListener("resize", handleResize)
}
}, [])

const [indexBothTeams, setIndexBothTeams]  = useState()
const [matchSVG, setMatchSVG] = useState([])
const [gpt_text, setGpt_text] = useState()
const [teamNames, setTeamNames] = useState([])
useEffect(()=>{
 if (todayGameData) {
for (let i = 0; i < todayGameData.length; i++) {
    
    if (todayGameData[i]['id'] === Number(matchId)) {
        
    setIndexBothTeams([todayGameData[i].homeTeam.id,todayGameData[i].awayTeam.id])
    setMatchSVG([todayGameData[i].homeTeam.crest, todayGameData[i].awayTeam.crest])
    // setGpt_text(GPT_response[i].GPT_response)
    setTeamNames([todayGameData[i].homeTeam.name,todayGameData[i].awayTeam.name])
    }
    // else{
    // setIndexBothTeams([todayGameData[0].homeTeam.id,todayGameData[0].awayTeam.id])
    // setMatchSVG([todayGameData[0].homeTeam.crest, todayGameData[0].awayTeam.crest])
    // setGpt_text(GPT_response[0].GPT_response)
    // setTeamNames([todayGameData[0].homeTeam.name,todayGameData[0].awayTeam.name])
    // }
}

    }
    
},[todayGameData])


function estatisticaButton(partidaId, homeTeamName, awayTeamName, idTime1, idTime2, homeSVG, awaySVG) {
    // for (let i = 0; i < GPT_response.length; i++) {
    //     const element = GPT_response[i];
    //     if (element.matchId === partidaId){
    //         setGpt_text(element.GPT_response)
    //         setTeamNames([homeTeamName, awayTeamName])
    //     }
    // }
    setIndexBothTeams([idTime1, idTime2])
    setMatchSVG([homeSVG, awaySVG])
    }

// FUNÇOES DO BOTAO renderMatchesToDisplay
    const [filteredTodayGameData, setFilteredTodayGameData] = useState()
    const  [bgColorButton,  setBgColorButton] = useState('bg-natural-color-button')
    useEffect(()=>{
        setFilteredTodayGameData(todayGameData)
        setBgColorButton('bg-natural-color-button')
        setButtonText('Todas as partidas') 
    },[todayGameData])
const [buttonText, setButtonText] = useState('Todas as partidas')
function renderMatchesToDisplay() {
    if (buttonText ===  'Todas as partidas') {
        setBgColorButton('bg-green-color-button')
        setButtonText('Partidas finalizadas')
        let copy = todayGameData.filter((gameData)=> gameData.status === "Encerrado")
        setFilteredTodayGameData(copy);
    }if (buttonText  === 'Partidas finalizadas') {
        setBgColorButton('bg-yellow-color-button')
        let copy = todayGameData.filter((gameData)=> gameData.status !== "Encerrado")
        setButtonText('Proximas partidas')
        setFilteredTodayGameData(copy);
    }if (buttonText  === 'Proximas partidas') {
        setBgColorButton('bg-natural-color-button')
        setButtonText('Todas as partidas')
        setFilteredTodayGameData(todayGameData);
    }}

    // GraphChild area  //

    const [matchesData, setMatchesData] = useState()
    
    useEffect(()=> {
        setMatchesData(fbrefData)
        }, []);

const [timeDaCasa, setTimeDaCasa] = useState()
const  [timeDeFora, setTimeDeFora] = useState()

function if_maior_que_0_parseFloat(value){
    if (value === 0 || value === false){
        return value
    }
    if (value !== 0 ) {
    return parseFloat(value.replace(',','.'))
    }
}
function devideTurnIntoFloat(value, quantidadeDePartidas) {
    if (value === 0 || value === false){
return value
    }
    if (value !== 0 ) {
    let v = value.replace(',','.')
    let devided =  parseFloat(v)/quantidadeDePartidas
    return parseFloat(devided.toLocaleString(undefined,{ maximumFractionDigits:2}).replace(',', '.'))
}else{
    return parseFloat(value)
}
}
useEffect(()=> {
    if (indexBothTeams){
        for (let i = 0; i < matchesData.length; i++) {
            const element = matchesData[i];
            if (element.id === indexBothTeams[0]) {
                let foundMatches = element
                let timeDaCasa_matchesPlayed = if_maior_que_0_parseFloat(foundMatches.matchesPlayed)
                let timeDaCasa_golsMedia = devideTurnIntoFloat(foundMatches.totalDeGol, timeDaCasa_matchesPlayed) 
                let timeDaCasa_GolPorChuteAGol_pct =if_maior_que_0_parseFloat(foundMatches.GolPorChuteAGol_pct)
                let timeDaCasa_avarangeShotDistance = if_maior_que_0_parseFloat(foundMatches.avarangeShotDistance)
                let timeDaCasa_bolasAereas_pct= if_maior_que_0_parseFloat(foundMatches.bolasAereas_pct)
                let timeDaCasa_impedimentos= devideTurnIntoFloat(foundMatches.impedimentos, timeDaCasa_matchesPlayed)
                let timeDaCasa_escanteios = if_maior_que_0_parseFloat((((foundMatches.escanteio)/foundMatches.matchesPlayed).toLocaleString(undefined,{ maximumFractionDigits:2})))
                let timeDaCasa_amarelo= devideTurnIntoFloat(foundMatches.yellowCard, timeDaCasa_matchesPlayed)
                let timeDaCasa_vermelho= devideTurnIntoFloat(foundMatches.redCard, timeDaCasa_matchesPlayed)
                let timeDaCasa_passes_pct= if_maior_que_0_parseFloat(foundMatches.passes_pct)
                let timeDaCasa_faltasCometidas= devideTurnIntoFloat(foundMatches.faltasCometidas, timeDaCasa_matchesPlayed)
                let timeDaCasa_gk_save_pct= if_maior_que_0_parseFloat(foundMatches.gk_save_pct)
                setTimeDaCasa({timeDaCasa_matchesPlayed,
                    timeDaCasa_golsMedia,
                    timeDaCasa_GolPorChuteAGol_pct,
                    timeDaCasa_avarangeShotDistance,
                    timeDaCasa_bolasAereas_pct,
                    timeDaCasa_impedimentos,
                    timeDaCasa_escanteios,
                    timeDaCasa_amarelo,
                    timeDaCasa_vermelho,
                    timeDaCasa_passes_pct,
                    timeDaCasa_faltasCometidas,
                    timeDaCasa_gk_save_pct})
            }
            if (element.id === indexBothTeams[1]) {
                let foundMatches = element
                let timeDeFora_matchesPlayed = if_maior_que_0_parseFloat(foundMatches.matchesPlayed)
                let timeDeFora_golsMedia = devideTurnIntoFloat(foundMatches.totalDeGol, timeDeFora_matchesPlayed)
                let timeDeFora_GolPorChuteAGol_pct =if_maior_que_0_parseFloat(foundMatches.GolPorChuteAGol_pct)
                let timeDeFora_avarangeShotDistance = if_maior_que_0_parseFloat(foundMatches.avarangeShotDistance)
                let timeDeFora_bolasAereas_pct= if_maior_que_0_parseFloat(foundMatches.bolasAereas_pct)
                let timeDeFora_impedimentos= devideTurnIntoFloat(foundMatches.impedimentos, timeDeFora_matchesPlayed)
                let timeDeFora_escanteios = if_maior_que_0_parseFloat((((foundMatches.escanteio)/foundMatches.matchesPlayed).toLocaleString(undefined,{ maximumFractionDigits:2})))
                let timeDeFora_amarelo= devideTurnIntoFloat(foundMatches.yellowCard, timeDeFora_matchesPlayed)
                let timeDeFora_vermelho= devideTurnIntoFloat(foundMatches.redCard, timeDeFora_matchesPlayed)
                let timeDeFora_passes_pct= if_maior_que_0_parseFloat(foundMatches.passes_pct)
                let timeDeFora_faltasCometidas= devideTurnIntoFloat(foundMatches.faltasCometidas, timeDeFora_matchesPlayed)
                let timeDeFora_gk_save_pct= if_maior_que_0_parseFloat(foundMatches.gk_save_pct)
                setTimeDeFora({timeDeFora_matchesPlayed,
                    timeDeFora_golsMedia,
                    timeDeFora_GolPorChuteAGol_pct,
                    timeDeFora_avarangeShotDistance,
                    timeDeFora_bolasAereas_pct,
                    timeDeFora_impedimentos,
                    timeDeFora_escanteios,
                    timeDeFora_amarelo,
                    timeDeFora_vermelho,
                    timeDeFora_passes_pct,
                    timeDeFora_faltasCometidas,
                    timeDeFora_gk_save_pct})
            }
        }
    }
}, [indexBothTeams])

    
    return (
        <div className="todayMatch">
<Helmet> 
    
    
    {matchId? 
    <>
    <title>{teamNames[0]+' '+ teamNames[1]+' Últimas Estatísticas e previsões.'}</title>
    <link rel="canonical" href={'https://palpitecertobet.com.br/'+matchId} />
    <meta
      name="description"
      content="Descubra estatísticas detalhadas e análises de futebol para ajudar você a fazer apostas certeiras. Nosso site oferece dados atualizados, previsões precisas e insights exclusivos para aumentar suas chances de sucesso nas apostas esportivas."
    />
    </>
    :
    ''
}
</Helmet>
        <div  className='selectMatchesToDisplayDiv'>
            <button className={bgColorButton} onClick={renderMatchesToDisplay}>{buttonText}</button>
            </div>
        <div className="rowOfCard">
        <Swiper 
        slidesPerView={slidesPerViewNum}
        pagination={{dynamicBullets:true, clickable:true}}
        navigation>
            {(typeof todayGameData === 'undefined')? (
                <p>loading ... </p>
            ):(filteredTodayGameData)?(
                filteredTodayGameData.map((match, i) => (
                    <SwiperSlide key={i}>
                    <RowOfCard i={i} estatisticaButton={estatisticaButton} match={match} />
                    </SwiperSlide>
                ))
            ):(
                todayGameData.map((match, i) => (
                    <SwiperSlide key={i}>
                    <RowOfCard i={i} estatisticaButton={estatisticaButton} match={match} />
                    </SwiperSlide>
                ))
            )}
        </Swiper>
        </div>
        <div className='gpt_poisson_parent'>
            <GptArea gpt_text={gpt_text} teamNames={teamNames} />
            {timeDaCasa ? 
            <Poisson teamNames={teamNames} timeDaCasa={timeDaCasa} timeDeFora={timeDeFora} />
            :''}
        </div>
        <div className='parentOfAll'>
        
        {(!timeDaCasa || !timeDeFora) ? ('') :(
        <div className='row-of-charts'>
        <Graphs indexBothTeams={indexBothTeams} matchSVG={matchSVG} timeDaCasa={timeDaCasa} timeDeFora={timeDeFora}  />
        <GraphChild timeDaCasa={timeDaCasa} timeDeFora={timeDeFora} /> 
        
        </div>
        )}
        
        </div>
        </div>
    )
}
export default TodayMatch