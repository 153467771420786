import '../styles/header.css'
import React, {useState, useEffect} from 'react';
import logoImage from '../image/logo192.png'
import campeonatoData from '../data/campeonatos.json'
import { useInView } from "react-intersection-observer";
import SideAd from './sideAd';

function Header(props) {
    const savedTodayGameData = props.savedTodayGameData
    const leagueIndexOutput = props.leagueIndexOutput

    const [ref, inView] = useInView({
        /* Optional options */
        triggerOnce: true,
        rootMargin: '0px 0px',
      })
      
const [campeonatosArr, setCampeonatosArr] = useState()

// Esse useEffect serve pra selecionar apenas os campeonatos existentes na semana
useEffect(()=> {
    if (savedTodayGameData){
        let campeonatoExistente = []
        for (let i = 0; i < savedTodayGameData.length; i++) {
            const element = savedTodayGameData[i];
            if (!campeonatoExistente.includes(element.competition.id)) {
                campeonatoExistente.push(element.competition.id)
            }}
        let campeonatoArr = []
        for (let i = 0; i < campeonatoData.length; i++) {
            const element = campeonatoData[i];
            if (campeonatoExistente.includes(element.id)) {
                campeonatoArr.push(element)
            }}
        setCampeonatosArr(campeonatoArr)
}
    }, [savedTodayGameData])

    return (
        <header className='header'>
            <title>Palpite Certo bet, Estatisticas futebolisticas em um só lugar!</title>
        
            <div className='logoImage-leagueDropDownMenu'>
                <a href='/'>
            <img src={logoImage} alt='logo whriten palpite certo bet'title='Main Logo'/>
            </a>
            <div className="paste-button">
                
  <button className="button">Campeonatos &nbsp; ▼</button>
  <div ref={ref} className="dropdown-content">
  {
  inView? (
    campeonatosArr.map((match, i) => (
        <div key={i} className='LeagueButtons'>
        <button  className='leagueButtons--child' onClick={() => leagueIndexOutput(match.id)}>
        <img className="smallestLogo campeonatoButton" alt="Escudo do respectivo time" src={match.emblem} />
        <div className='exAncorTag'>{match.name}</div>
        </button>
        </div>
    ))
):(
    <p>loading ... </p>
            )}
  </div>
  
</div>
<h1 className='unselectable'>Todas as estatisticas em um só lugar</h1>

</div>
</header>
    )
}

export default Header