import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import App from './App';
import Policy from './about/policy';
import Service from './about/service';
import { HelmetProvider } from 'react-helmet-async';
import {} from 'swiper/element/bundle'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    <HelmetProvider>
    <Routes>
        <Route path='/'>
            <Route index={true} element={<App />}/>
        </Route>
        <Route path="politica-de-privacidade">
        <Route index={true} element={<Policy/>} />
        </Route>
        <Route path="termos-de-servico">
        <Route index={true} element={<Service/>} />
        </Route>
        <Route index={true} path="/:matchId" element={<App />} />
    </Routes>
    </HelmetProvider>
    </BrowserRouter>
);

