import './styles/compTable.css'
import React, {useState} from "react";
import campeonatoData from './data/comp_Scrape.json'

function CompTable() {

    const [competition, setCompetition] = useState(campeonatoData[2013])
    
function get_compHead() {
    let competitionHead = []
    for (let i = 0; i < Object.keys(campeonatoData).length; i++) {
        const element = campeonatoData[Object.keys(campeonatoData)[i]];
        
        if (element[0].length === 0) {
            continue
        }else{
            element[0][0].competitionHead['compId'] = Object.keys(campeonatoData)[i]
            competitionHead.push(element[0][0].competitionHead)
        }
    }
    
    return competitionHead
}
const [competitionButtonActive, setCompetitionButtonActive] = useState({})
    function pushNewCompetition(id,compId) {
        setCompetition(campeonatoData[compId])
        setCompetitionButtonActive({
            [id]:competitionButtonActive
        })
}
function sortable(rowName) {
    for (let i = 0; i < competition.length; i++) {
        
        // let selectedColumn = document.querySelectorAll("[data-row="+rowName+"]")
    }
    setCompetition([competition[0].reverse()])
}


    return (
<div className="parentOfComp_table">
{competition ?
<>
<div className="competitionButtonsDivParent">
<div className="competitionButtonsDiv">

{get_compHead().map((compHead,i)=>(

<div key={i}
        style={{
            boxShadow: competitionButtonActive[`${i}`] 
            ? "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset" 
            : ""
        }} className='competitionButtons' onClick={() => pushNewCompetition(i,compHead.compId)}>
    <img src={compHead.imageSrc} alt="logo da competição" title="logo da competição"/>
    <div className="compHeadSpans">
    {compHead.compName.map((compName)=>(
        compName+' '
    ))}</div>
</div>

))}
</div>
</div>
<div className="competitionHead">
<h3>Ano: {competition[0][0].competitionHead.compYear}</h3>
</div>
<table>
    {competition.map((compChild, i) => (
    <>
<thead key={i}>
    <h3>{compChild[0].caption.includes('Grupo')? compChild[0].caption :  ''}</h3>
    <tr>
     <th className="firstTh tooltip" onClick={()=>sortable('classification')}>Equipe<span className="tooltiptext"><b>Classificação</b><br/>Posição Atual/Final da <br/> equipe no campeonato.<br/><br/> <strong>F</strong>: Final <br/> <strong>SF</strong>: Semi-Final<br/> <strong>QF</strong>: Quartas de Final<br/> <strong>R16</strong>: Oitavas de Final</span></th>
    <th className="tooltip" /* onClick={()=>sortable('matchesPlayed')} */>JD<span className="tooltiptext"><b>Jogos Disputados</b></span></th>
    <th className="tooltip" /*  onClick={()=>sortable('wins')}  */>V<span className="tooltiptext"><b>Vitórias</b></span></th>
    <th className="tooltip">E<span className="tooltiptext"><b>Empates</b></span></th> 
    <th className="tooltip">D<span className="tooltiptext"><b>Derrotas</b></span></th>
    <th className="tooltip">GF<span className="tooltiptext"><b>Gols Feitos</b></span></th>
    <th className="tooltip">GS<span className="tooltiptext"><b>Gols Sofridos</b></span></th>
    <th className="tooltip">GD<span className="tooltiptext"><b>Diferença de Gols</b></span></th>
    <th className="tooltip">Pt<span className="tooltiptext"><b>Pontos</b><br/>A maioria das ligas são orientadas por pontos<br/> três para uma vitória e um para empate.</span></th>
    {compChild[0].pontos_avg ? <th className="tooltip">Pts/P<span className="tooltiptext"><b>Média de Pontos</b><br/> Média de pontos por partidas.</span></th>:''}
{compChild[0].result_tip.length ? <th className="tooltip ultimos5">Últimos 5<span className="tooltiptext"><b>Últimas 5 partidas</b><br/>As partidas estão ordenadas da esquerda para direita.</span></th>  :  ''}
{compChild[0].artilheiros[0] ? <th className="tooltip artilheirosName">artilheiros<span className="tooltiptext"><b>Artilheiros em destaque</b><br/>Inclui apenas as partidas do<br/> campeonato em questão.</span></th>:''}
{compChild[0].artilheiros[0] ? <th className="tooltip">Gols<span className="tooltiptext"><b>Gols</b><br/>Gols do artilheiro em questão.</span></th>:''}
</tr>
    </thead>
    <tbody>
    {(compChild.map((comp, i)=>
        <tr key={i} data-row={i}>
            <td className="teamMatch">
                <div className={'classification_area '+comp.classification.class[1]+' '+comp.classification.class[2]} >
                    <p data-row='classification'>{comp.classification.position}</p>
                </div>
                <hr/>
                <div className="iconAndTeamName">
                <img className="smallIconField" src={comp.equipe.imgSrc} alt="brazão do time" /><br/>
                    <span>{comp.equipe.time}</span>
                <br/>
            </div>
            </td>
            <td data-row='matchesPlayed' className="" >{comp.matchesPlayed}</td>
            <td data-row='wins' className="color-green">{comp.wins}</td>
            <td data-row='ties' className="">{comp.ties}</td>
            <td data-row='losses' className="color-red">{comp.losses}</td>
            <td data-row='golFeito' className="color-green">{comp.golFeito}</td>
            <td data-row='golSofrido' className="color-red">{comp.golSofrido}</td>
            <td data-row='golDiff' className="">{comp.golDiff}</td>
            <td data-row='pontos' className="">{comp.pontos}</td>
            {comp.pontos_avg ? <td data-row='pontos_avg' className="">{comp.pontos_avg}</td>:''}
            {comp.result_tip.length ?  (<td data-row='ultimos5' className="result_tip ">
                {comp.result_tip.map((tip, i)=>(
                <span key={i} className={"tooltip last5 "+tip.result}>
                    <span className="tooltiptext" dangerouslySetInnerHTML={{__html: tip.tip}}>
                    </span>
                        {tip.result}
                    </span>))}
                    </td>):''}
            {compChild[0].artilheiros.length ? (<td data-row='artilheiros' className="artilheiros_td">
                {comp.artilheiros.map((artilheiro, i)=>
                <span key={i}>- {artilheiro.player}</span>
            )}</td>)
            :''}
            {compChild[0].artilheiros.length ? 
            (<td data-row='artilheiros_gol' >{comp.artilheiros[0] ? <span>{comp.artilheiros[0].player_pts}</span> : '-'}</td>)
            :''}
            </tr>
        ))}
        </tbody>
        </>
        ))}
</table>
</>:''}
<div className="qualifierParent">

    
    <div className="qualifierArea">
        <div className="qualifierTips qualifier1">Campeonato da primeira divisão</div>
        <span>
            Qualificado para a competição de primeira linha. (Ex: UEFA Champions League), ou na zona de qualificação.
        </span>
        </div>
        <div className="qualifierArea">
        <div className="qualifierTips qualifier2">Campeonato da segunda divisão</div>
        <span>
            Qualificado para a competição de primeira linha. (Ex: UEFA Europa League) ou na zona de qualificação.
        </span>
        </div>
        <div className="qualifierArea">
        <div className="qualifierTips qualifier3">Campeonato da terceira divisão</div>
        <span>
            Qualificado para a competição de primeira linha. (Ex: UEFA Europa Conference League), ou na zona de qualificação.
        </span>
        </div>
        <div className="qualifierArea">
        <div className="qualifierTips playoffTip">Eliminatória de rebaixamento</div>
        <span>
        Qualificado para eliminatória de rebaixamento ou na zona de rebaixamento.
        </span>
        </div>
        <div className="qualifierArea">
        <div className="qualifierTips relegateTip">Rebaixado</div>
        <span>
        Equipe foi rebaixada ou está na zona de rebaixamento.
        </span>
        </div>
        </div>
</div>
)
}

export default CompTable