import React from 'react';


function GraphChild(props){
    const timeDaCasa = props.timeDaCasa
    const timeDeFora = props.timeDeFora
    
    function colorChange(time1, time2) {
        if (time1 > time2) {
            return 'backgroundGreen'
        }if (time1 < time2) {
            return 'backgroundRed'
        }else {
            return 'backgroundGreen'
        }
    }

return (
<div className='parent_of_all'>
{/* {console.log(timeDaCasa.timeDaCasa_matchesPlayed)} */}
    {/* MEDIA DE GOL */}
{timeDaCasa.timeDaCasa_golsMedia && timeDeFora.timeDeFora_golsMedia ? 
<div className='graphChild-parent'> 
<div className='bars-container left'>
<span className={'left '+colorChange( timeDaCasa.timeDaCasa_golsMedia, timeDeFora.timeDeFora_golsMedia)}>{timeDaCasa.timeDaCasa_golsMedia}</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_golsMedia*50}}>
</div>
</div>
<div className='colum top'>
<p>Media de Gols</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_golsMedia*50}}>
</div>
<span className={'right '+colorChange(timeDeFora.timeDeFora_golsMedia, timeDaCasa.timeDaCasa_golsMedia)}>{timeDeFora.timeDeFora_golsMedia}</span>
</div>
</div>
: ''}
{/* GOL POR CHUTES A GOL */}
{timeDaCasa.timeDaCasa_GolPorChuteAGol_pct && timeDeFora.timeDeFora_GolPorChuteAGol_pct ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange(timeDaCasa.timeDaCasa_GolPorChuteAGol_pct, timeDeFora.timeDeFora_GolPorChuteAGol_pct)}>{timeDaCasa.timeDaCasa_GolPorChuteAGol_pct}</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_GolPorChuteAGol_pct*100}}>
    </div>
    </div>
<div className='colum'>
<p>Gol por Chutes A Gol</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_GolPorChuteAGol_pct*100}}>

</div>
<span className={'right '+colorChange(timeDeFora.timeDeFora_GolPorChuteAGol_pct, timeDaCasa.timeDaCasa_GolPorChuteAGol_pct)}>{timeDeFora.timeDeFora_GolPorChuteAGol_pct}</span>
</div>
</div>
: ''}
{/* PASSES QUE LEVARAM A GOL */}
{timeDaCasa.timeDaCasa_passes_pct && timeDeFora.timeDeFora_passes_pct ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange(timeDaCasa.timeDaCasa_passes_pct, timeDeFora.timeDeFora_passes_pct)}>{timeDaCasa.timeDaCasa_passes_pct}%</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_passes_pct*1.2}}>
    </div>
    </div>
<div className='colum'>
<p>Passes que Levam A Gol</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_passes_pct*1.2}}>

</div>
<span className={'right '+colorChange(timeDeFora.timeDeFora_passes_pct, timeDaCasa.timeDaCasa_passes_pct)}>{timeDeFora.timeDeFora_passes_pct}%</span>
</div>
</div>
: ''}
{/* MEDIA DE DISTANCIA DE CHUTE */}
{timeDeFora.timeDeFora_avarangeShotDistance && timeDaCasa.timeDaCasa_avarangeShotDistance ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange(timeDeFora.timeDeFora_avarangeShotDistance,timeDaCasa.timeDaCasa_avarangeShotDistance)}>{timeDaCasa.timeDaCasa_avarangeShotDistance}</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_avarangeShotDistance*4}}>
    </div>
    </div>
<div className='colum'>
<p>Distancia de Chute a Gol</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_avarangeShotDistance*4}}>
</div>
<span className={'right '+colorChange(timeDaCasa.timeDaCasa_avarangeShotDistance, timeDeFora.timeDeFora_avarangeShotDistance)}>{timeDeFora.timeDeFora_avarangeShotDistance}</span>
</div>
</div>
: ''}
{/* BOLAS AEREAS GANHAS */}
{timeDaCasa.timeDaCasa_bolasAereas_pct && timeDeFora.timeDeFora_bolasAereas_pct ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange(timeDaCasa.timeDaCasa_bolasAereas_pct,timeDeFora.timeDeFora_bolasAereas_pct)}>{timeDaCasa.timeDaCasa_bolasAereas_pct}%</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_bolasAereas_pct*2}}>
    </div>
    </div>
<div className='colum'>
<p>Bolas Aereas Ganhas</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_bolasAereas_pct*2}}>
</div>
<span className={'right '+colorChange(timeDeFora.timeDeFora_bolasAereas_pct, timeDaCasa.timeDaCasa_bolasAereas_pct)}>{timeDeFora.timeDeFora_bolasAereas_pct}%</span>
</div>
</div>
: ''}
{/* IMPEDIMENTOS */}
{timeDeFora.timeDeFora_impedimentos  && timeDaCasa.timeDaCasa_impedimentos ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange(timeDeFora.timeDeFora_impedimentos, timeDaCasa.timeDaCasa_impedimentos)}>{timeDaCasa.timeDaCasa_impedimentos}</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_impedimentos*20}}>
    </div>
    </div>
<div className='colum'>
<p>Impedimentos</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_impedimentos*20}}>
</div>
<span className={'right '+colorChange(timeDaCasa.timeDaCasa_impedimentos,timeDeFora.timeDeFora_impedimentos)}>{timeDeFora.timeDeFora_impedimentos}</span>
</div>
</div>
: ''}
{/* ESCANTEIOS */}
{timeDaCasa.timeDaCasa_escanteios && timeDeFora.timeDeFora_escanteios ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange(timeDaCasa.timeDaCasa_escanteios, timeDeFora.timeDeFora_escanteios)}>{timeDaCasa.timeDaCasa_escanteios}</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_escanteios*10}}>
    </div>
    </div>
<div className='colum'>
<p>Escanteios</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_escanteios*10}}>
</div>
<span className={'right '+colorChange(timeDeFora.timeDeFora_escanteios, timeDaCasa.timeDaCasa_escanteios)}>{timeDeFora.timeDeFora_escanteios}</span>
</div>
</div>
: ''}
{/* CARTAO AMARELO */}
{timeDeFora.timeDeFora_amarelo && timeDaCasa.timeDaCasa_amarelo ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange(timeDeFora.timeDeFora_amarelo, timeDaCasa.timeDaCasa_amarelo)}>{timeDaCasa.timeDaCasa_amarelo}</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_amarelo*30}}>
    </div>
    </div>
<div className='colum'>
<p>Cartões Amarelos</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_amarelo*30}}>
</div>
<span className={'right '+colorChange(timeDaCasa.timeDaCasa_amarelo, timeDeFora.timeDeFora_amarelo)}>{timeDeFora.timeDeFora_amarelo}</span>
</div>
</div>
: ''}
{/* CARTAO VERMELHO */}
{timeDeFora.timeDeFora_vermelho && timeDaCasa.timeDaCasa_vermelho ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange(timeDeFora.timeDeFora_vermelho, timeDaCasa.timeDaCasa_vermelho)}>{timeDaCasa.timeDaCasa_vermelho}</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_vermelho*100}}>
    </div>
    </div>
<div className='colum'>
<p>Cartões Vermelhos</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_vermelho*100}}>
</div>
<span className={'right '+colorChange(timeDaCasa.timeDaCasa_vermelho, timeDeFora.timeDeFora_vermelho)}>{timeDeFora.timeDeFora_vermelho}</span>
</div>
</div>
: ''}
{/* SUCESSO EM PASSES*/}
{timeDaCasa.timeDaCasa_passes_pct && timeDeFora.timeDeFora_passes_pct ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange( timeDaCasa.timeDaCasa_passes_pct,timeDeFora.timeDeFora_passes_pct)}>{timeDaCasa.timeDaCasa_passes_pct}%</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_passes_pct}}>
    </div>
    </div>
<div className='colum'>
<p>Passes bem sucedidos</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_passes_pct}}>
</div>
<span className={'right '+colorChange(timeDeFora.timeDeFora_passes_pct,timeDaCasa.timeDaCasa_passes_pct)}>{timeDeFora.timeDeFora_passes_pct}%</span>
</div>
</div>
: ''}
{/* DEFESAS GOLEIRO */}
{timeDaCasa.timeDaCasa_gk_save_pct && timeDeFora.timeDeFora_gk_save_pct ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange( timeDaCasa.timeDaCasa_gk_save_pct,timeDeFora.timeDeFora_gk_save_pct)}>{timeDaCasa.timeDaCasa_gk_save_pct}%</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_gk_save_pct}}>
    </div>
    </div>
<div className='colum'>
<p>% Defesas Goleiro</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_gk_save_pct}}>
</div>
<span className={'right '+colorChange(timeDeFora.timeDeFora_gk_save_pct,timeDaCasa.timeDaCasa_gk_save_pct)}>{timeDeFora.timeDeFora_gk_save_pct}%</span>
</div>
</div>
: ''}
{/* FALTAS COMETIDAS*/}
{timeDeFora.timeDeFora_faltasCometidas && timeDaCasa.timeDaCasa_faltasCometidas ? 
<div className='graphChild-parent'>
<div className='bars-container left'>
<span className={'left '+colorChange( timeDeFora.timeDeFora_faltasCometidas, timeDaCasa.timeDaCasa_faltasCometidas)}>{timeDaCasa.timeDaCasa_faltasCometidas}</span>
    <div className='bars'style={{width:timeDaCasa.timeDaCasa_faltasCometidas*5}}>
    </div>
    </div>
<div className='colum bot'>
<p>Faltas Cometidas</p>
</div>
<div className='bars-container right'>
<div className='bars' style={{width:timeDeFora.timeDeFora_faltasCometidas*5}}>
</div>
<span className={'right '+colorChange(timeDaCasa.timeDaCasa_faltasCometidas, timeDeFora.timeDeFora_faltasCometidas)}>{timeDeFora.timeDeFora_faltasCometidas}</span>
</div>
</div>
: ''}
</div>
)
}
export default GraphChild