import React from "react";
import '../styles/poisson.css'

function Poisson(props) {
    const timeDaCasa = props.timeDaCasa
    const timeDeFora = props.timeDeFora
    const teamNames = props.teamNames

function factorial(n) {
    return n ? n * factorial(n - 1) : 1;
}

function poissonProbability(lambda, k) {
    let r = ((Math.exp(-lambda) * Math.pow(lambda, k)) / factorial(k))
    r = parseFloat(r)*100
    r = r.toFixed(2)
    r = parseFloat(r)
    return r
}
function empateProbability(lambda1, lambda2, maxGoals) {
    let probability = 0;
    for (let k = 0; k <= maxGoals; k++) {
        let prob1 = poissonProbability(lambda1, k)/100;
        let prob2 = poissonProbability(lambda2, k)/100;
        probability += prob1 * prob2;
    }
    probability =  probability.toFixed(2)
    probability = (parseFloat(probability)*100).toFixed(2)

    return parseFloat(probability);
}
function probabilityAtLeastOneGoal(lambda) {
    let r  = 1 - Math.exp(-lambda)
    r = r.toFixed(2)
    r= parseFloat(r)
    return r;
}
function probabilityBothTeamsAtLeastOneGoal(lambda1, lambda2) {
    let probTime1 = probabilityAtLeastOneGoal(lambda1);
    let probTime2 = probabilityAtLeastOneGoal(lambda2);
    let both = probTime1 * probTime2
    both = parseFloat(both.toFixed(2))
    both = both.toFixed(2)*100
    return parseFloat(both)
}

    return  (
        <>
    
    <div className="poissonAreaParent">
        <h2><span className="h2odds">Odds da partida </span> </h2>
        <span className="team2name"></span>
    <ul>
        <li>Dados baseados em <span>{timeDaCasa.timeDaCasa_matchesPlayed+timeDeFora.timeDeFora_matchesPlayed} Jogos</span></li>

        <li><span>Empate</span> {empateProbability(timeDaCasa.timeDaCasa_golsMedia,timeDeFora.timeDeFora_golsMedia,10)}%</li>
        <li><span>Ambos marcam</span>
        {
        probabilityBothTeamsAtLeastOneGoal(timeDaCasa.timeDaCasa_golsMedia,timeDeFora.timeDeFora_golsMedia).toFixed(0)
        }%</li>
        <li><span>Mais de 1,5 </span>{
        (poissonProbability(timeDaCasa.timeDaCasa_golsMedia, 1)
        + poissonProbability(timeDeFora.timeDeFora_golsMedia, 2)).toFixed(2)
        }%</li>
        
        <li><span>Mais de 2,5 </span>{
        (poissonProbability(timeDaCasa.timeDaCasa_golsMedia, 2)
        + poissonProbability(timeDeFora.timeDeFora_golsMedia, 3)).toFixed(2)
        }%</li>
        <li><span>Mais de 3,5 </span>{
        (poissonProbability(timeDaCasa.timeDaCasa_golsMedia, 3)
        + poissonProbability(timeDeFora.timeDeFora_golsMedia, 4)).toFixed(2)
        }%</li>
            <div className="div_interno"><b>pelo menos 1 gol</b>
        <li><span className="team1name">{teamNames[0]}</span> {(probabilityAtLeastOneGoal(timeDaCasa.timeDaCasa_golsMedia)*100).toFixed(0)}%</li>
        <li><span className="team2name">{teamNames[1]}</span> {(probabilityAtLeastOneGoal(timeDeFora.timeDeFora_golsMedia)*100).toFixed(0)}%</li>
        </div>
        <div className="div_interno">
            <b>Gol depois dos 80"</b>
        <li><span className="team1name">{teamNames[0]}</span> {(probabilityAtLeastOneGoal((timeDaCasa.timeDaCasa_golsMedia/90)*10)*100).toFixed(0)}%</li>
        <li><span className="team2name">{teamNames[1]}</span> {(probabilityAtLeastOneGoal((timeDeFora.timeDeFora_golsMedia/90)*10)*100).toFixed(0)}%</li>
        </div>
</ul>
        </div>

    </>
    )}

export default Poisson