import React, { useEffect, useState } from "react";
import head2headData from '../data/today-head2head.json'

function Head2head(props) {
    let win = 0
    let draw = 0
    let losses = 0
    const team1Crest = props.team1Crest
    const team2Crest = props.team2Crest
    const team1name = props.team1name
    const team2name = props.team2name
    const matchId = props.matchId
    
const [head2head,  setHead2head] = useState([])

useEffect(()=> {
    setHead2head(head2headData)
  }, [])
  for (let i = 0; i < head2head.length; i++) {
    const element = head2head[i][matchId];
    if (element) {
      win = element.wins
      draw = element.draws
      losses = element.losses
    }
  }
    return (
<div className="confronto-direto-div">
{(head2head) ? (
  <div>
  <h5 >Confrontos diretos</h5>
  <div className="head2headArea">
  <img className="smallestLogo" alt="Escudo do respectivo time" src={team1Crest} title={team1name} />
      <span >{win}</span>
      <span>:</span><span >{draw}</span>
      <span>:</span><span >{losses}</span>
  <img className="smallestLogo" alt="Escudo do respectivo time" src={team2Crest} title={team2name} />
</div>
</div>
) 
: 
(
''
)
}
  </div>
    
    )
}
export default Head2head