import React from "react";
import '../styles/footer.css'
function Footer() {
    return(
        <div className="footer-parent">
                <div className="help-area">
    <a href='/termos-de-servico'>Termos de serviço</a>
    <a href='/politica-de-privacidade'>Política de privacidade</a>
    </div>
        <div className="tm-area">
            <h4>© 2024 Palpite Certo. Todos os direitos reservados.</h4>
        </div>
        

    </div>
)
}

export default Footer